import React, { useState, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";

// Component imports
import LoginForm from "./components/LoginForm";

import CustomerCard from "./components/CustomerCard";
import ArticleList from "./components/ArticleList";
import ReturnList from "./components/ReturnList";
import Confirmation from "./components/Confirmation";
import Loader from "./components/Loader";

const returnAPIBaseUrl = process.env.REACT_APP_API_BASE_URL;
const upsMrapLinkreturnWindowInDays = process.env.REACT_APP_UPS_MRAP_LINK_RETURN_WINDOW_IN_DAYS;

function App() {
  // State management
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  const [orderData, setOrderData] = useState(
    JSON.parse(sessionStorage.getItem("orderData"))
  );
  const [articleList, setArticleList] = useState(
    JSON.parse(sessionStorage.getItem("articleList"))
  );
  const [missingReasonList, setMissingReasonList] = useState(null);
  const [returnList, setReturnList] = useState(
    JSON.parse(sessionStorage.getItem("returnList"))
  );
  const [ups, setUps] = useState(false);
  const [dhl, setDhl] = useState(false);
  const [dhlToUpsReturn, setDhlToUpsReturn] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [commentPosted, setCommentPosted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [labelSupport, setLabelSupport] = useState(false);
  const [supportError, setSupportError] = useState(false);
  const [exceptionSwitzerland, setExceptionSwitzerland] = useState(false);

  // Page navigation logic
  let navigate = useNavigate();

  // Fetching data logic

  const getOrderData = () => {
    axios
      .get(`${returnAPIBaseUrl}/articles`)
      .then((response) => {
        sessionStorage.setItem("orderData", JSON.stringify(response.data));
        setOrderData(JSON.parse(sessionStorage.getItem("orderData")));

        const articleList = response.data.articleList;
        const articleListWithCheckedProperty = articleList.map((item) => {
          return { ...item, checked: false };
        });
        sessionStorage.setItem(
          "articleList",
          JSON.stringify(articleListWithCheckedProperty)
        );
        setArticleList(JSON.parse(sessionStorage.getItem("articleList")));
      })
      .chatch((error) => {
        console.log(error);
        // set any kind of error state here
      });
  };

  const createReturnDataObject = () => {
    const relevantArticleData = [];
    returnList.forEach((article) => {
      const relevantData = {
        articleNumber: article.articleNumber,
        reason: article.returnReason,
        quantity: article.returnQuantity,
      };
      relevantArticleData.push(relevantData);
    });
    const returnData = {
      orderNumber: orderData.orderNumber,
      articles: relevantArticleData,
      userComment: userComment,
    };

    return returnData;
  };

  const postOrderComment = async () => {
    // only need to pass relevant article data to backend/ customer service like return rason etc.
    const requestBodyData = createReturnDataObject();

    setIsLoading(true);
    const response = await axios.post(`${returnAPIBaseUrl}/postReturn`, {
      data: requestBodyData,
    });

    setIsLoading(false);
    return response;
  };

  const postReturnTrackingNumber = async (returnTrackingNumber) => {
    const requestBodyData = {
      orderNumber: orderData.orderNumber,
      trackingNumber: returnTrackingNumber,
      shippingRequestNumber: orderData.shippingRequestNumber,
      packageNumber: orderData.packageId
    };
    setIsLoading(true);
    const response = axios.post(`${returnAPIBaseUrl}/postReturn`, {
      data: requestBodyData,
    });
    setIsLoading(false);
    return response;
  };

  const requestDhlLabel = async () => {
    const DHL_URL = `${returnAPIBaseUrl}/dhlLabel`;
    const requestBodyData = {
      orderData: orderData,
    };
    setIsLoading(true);
    try {
      const response = await axios.post(DHL_URL, { data: requestBodyData });
      console.log("response from requestDhlLabel() post");
      console.log(response);
      setIsLoading(false);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const requestUpsLabelFromOriginalTracking = async () => {
    const UPS_URL = `${returnAPIBaseUrl}/upsLabel`;
    const requestBodyData = {
      orderData: orderData,
    };
    setIsLoading(true);
    const response = await axios.post(UPS_URL, { data: requestBodyData });
    setIsLoading(false);

    return response;
  };

  const requestCustomUpsLabel = async () => {
    const UPS_CUSTOM_URL = `${returnAPIBaseUrl}/customUpsLabel`;
    const requestBodyData = {
      orderData: orderData,
      returnList: returnList,
    };
    setIsLoading(true);
    const response = await axios.post(UPS_CUSTOM_URL, {
      data: requestBodyData,
    });
    setIsLoading(false);
    console.log(response);
    return response;
  };

  const sendMailToSupport = async (customerCountryCode, reason) => {
    if (reason === undefined) {
      reason = "Customer needs support with label";
    }
    const SUPPORT_URL = `${returnAPIBaseUrl}/labelSupport`;
    const returnData = createReturnDataObject();

    const requestBodyData = {
      returnData: returnData,
      reason: reason,
      countryCode: customerCountryCode,
      orderData: orderData,
    };
    setIsLoading(true);
    const response = await axios.post(SUPPORT_URL, { data: requestBodyData });
    setIsLoading(false);
    return response;
  };

  // Click handler logic

  const onLoggedIn = () => {
    setUserData(JSON.parse(sessionStorage.getItem("user")));

    getOrderData();
  };

  const toggleCheck = (index) => {
    let mapped = articleList.map((articleInfo, i) => {
      // return articleInfo.articleNumber === articleNumber ? { ...articleInfo, checked: !articleInfo.checked } : { ...articleInfo };
      return i === index
        ? { ...articleInfo, checked: !articleInfo.checked }
        : { ...articleInfo };
    });
    sessionStorage.setItem("articleList", JSON.stringify(mapped));
    setArticleList(JSON.parse(sessionStorage.getItem("articleList")));
  };

  const handleReturnReasonSelect = (event, index) => {
    const returnReason = parseInt(event.target.value);
    if (returnReason && returnReason > 0) {
      let mapped = articleList.map((articleInfo, i) => {
        //return articleInfo.articleNumber === article.articleNumber ? { ...articleInfo, returnReason: returnReason } : { ...articleInfo };
        return i === index
          ? { ...articleInfo, returnReason: returnReason, returnQuantity: 1 }
          : { ...articleInfo };
      });

      sessionStorage.setItem("articleList", JSON.stringify(mapped));
      setArticleList(JSON.parse(sessionStorage.getItem("articleList")));
    }
  };

  const handleQuantitySelect = (event, index) => {
    const quantity = parseInt(event.target.value) || 1; // if there is only 1 article, no value will be passed (bc no dropdown is present)
    if (quantity === 0) {
      console.log("no quantity selected");
    } else {
      let mapped = articleList.map((articleInfo, i) => {
        return i === index
          ? { ...articleInfo, returnQuantity: quantity }
          : { ...articleInfo };
      });
      sessionStorage.setItem("articleList", JSON.stringify(mapped));
      setArticleList(JSON.parse(sessionStorage.getItem("articleList")));
    }
  };

  const handleUserCommentChange = (event) => {
    event.preventDefault();
    const text = event.target.value;
    // remove html tags if existing - for security reasons - there is additional sanitizing in backend
    const regex = /(<([^>]+)>)/gi;
    const cleanText = text.replace(regex, "");
    setUserComment(cleanText);
  };

  const handleListSubmit = () => {
    const checkedList = articleList.filter((article) => article.checked);
    const missingReasonList = checkedList.filter(
      (article) => !article.returnReason
    );
    if (missingReasonList.length) {
      setMissingReasonList(missingReasonList);
    } else {
      sessionStorage.setItem("returnList", JSON.stringify(checkedList));
      setReturnList(JSON.parse(sessionStorage.getItem("returnList")));
      navigate("returns");
    }
  };

  const handleLabelRequest = async () => {
    navigate("confirmation");
    // console.log('userData:',userData);
    // console.log('orderData:',orderData);
    // const email = orderData.customer.email;

    const originalCarrier = orderData.carrier;

    const customerCountryCode = orderData.customer.address.countryCode;

    // const orderDate = orderData.orderDate;
    const shipoutDate = orderData.articleList[0].shipoutDate;
    // const articleListlength = orderData.articleList.length;

    // console.log('orderDate:',orderDate);
    // console.log('articleListlength:',articleListlength);
    // console.log('shipoutDate:',shipoutDate);
    // console.log('originalTrackingNumber:',orderData.originalTrackingNumber);
    // console.log('upsMrapLinkreturnWindowInDays:',upsMrapLinkreturnWindowInDays);

    setIsLoading(true);
    const response = await postOrderComment();

    if (response.status === 200) {
      setCommentPosted(true);

      if (originalCarrier.includes("UPS") && customerCountryCode === "CH" && !userData?.supportLogin) {
        setLabelSupport(true);
        setExceptionSwitzerland(true);
        setIsLoading(false);
      } else if (
        originalCarrier.includes("UPS") &&
        customerCountryCode !== "GB" &&
        customerCountryCode !== "NO" &&
        customerCountryCode !== "US"
      ) {
        const regExForTrackingNo =
          /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/;
        let daysDifference = getDaysDifference(shipoutDate,new Date());
        const originalTrackingNumber = orderData.originalTrackingNumber;
        let upsLabelUrl = "";
        if (regExForTrackingNo.test(originalTrackingNumber) && (!userData?.supportLogin || daysDifference < upsMrapLinkreturnWindowInDays)) {
          setUps(true);

          upsLabelUrl = `https://www.ups.com/uel/mrap/${originalTrackingNumber}`;
          sessionStorage.setItem("upsLink", upsLabelUrl);
          const response = await requestUpsLabelFromOriginalTracking();

          if (response.status === 200) {
            setEmailSent(true);
          } else {
            setLabelSupport(true);
            let reason = `Original Carrier: ${originalCarrier}. Link could not be sent to customer`;
            const response = await sendMailToSupport(
              customerCountryCode,
              reason
            );
            if (response.status === 200) {
              setIsLoading(false);
            } else {
              setLabelSupport(false);
              setSupportError(true);
            }
          }
        } else {
          let response = await requestCustomUpsLabel();
          console.log(response);

          if (response) {
            setEmailSent(true);
            const returnTrackingNumber = response.data; // make sure you return this from customUpsLabel endpoint
            postReturnTrackingNumber(returnTrackingNumber);
            setDhlToUpsReturn(true);
            setIsLoading(false);
          }
          else {
            setLabelSupport(true);
            let reason = `Original Carrier: ${originalCarrier}. No valid tracking number available from original shipping`;
            response = await sendMailToSupport(customerCountryCode, reason);
            if (response.status === 200) {
              setIsLoading(false);
            } else {
              setLabelSupport(false);
              setSupportError(true);
            }
          }
        }
      } 
	else if (
        (originalCarrier.includes("DHL") ||
          originalCarrier.includes("DTPG") ||
          originalCarrier.includes("DTPM") ||
          originalCarrier.includes("DHLW") ||
          originalCarrier.includes("DHLWI") ||
          originalCarrier.includes("DHLEXPRESS")) &&
        (customerCountryCode === "AT" ||
          customerCountryCode === "DE" ||
          customerCountryCode === "NL" ||
          customerCountryCode === "SE" ||
          customerCountryCode === "IT" ||
          customerCountryCode === "FR" ||
          customerCountryCode === "FI" || 
          customerCountryCode === "PL" || 
          customerCountryCode === "PT" || 
          customerCountryCode === "BE" || 
          customerCountryCode === "SK" || 
          customerCountryCode === "CZ" || 
          customerCountryCode === "HU" || 
          customerCountryCode === "GR" || 
          customerCountryCode === "RO" || 
          customerCountryCode === "BG" || 
          customerCountryCode === "LU" || 
          customerCountryCode === "DK" ||
          customerCountryCode === "IE" ||
          customerCountryCode === "CY" ||
          customerCountryCode === "HR" ||
          customerCountryCode === "ES")
      ) {
        console.log("case DHL + " + customerCountryCode);
        setDhlToUpsReturn(true);

        const response = await requestCustomUpsLabel();
        console.log(response);

        if (response) {
          setEmailSent(true);
          const returnTrackingNumber = response.data; // make sure you return this from customUpsLabel endpoint
          postReturnTrackingNumber(returnTrackingNumber);
          setIsLoading(false);
        } else {
          setLabelSupport(true);
          let reason = `Original Carrier: ${originalCarrier}. Custom UPS return label could not be generated properly. Please handle manually`;
          const response = await sendMailToSupport(customerCountryCode, reason);
          if (response.status === 200) {
            setIsLoading(false);
          } else {
            setLabelSupport(false);
            setSupportError(true);
            setIsLoading(false);
          }
        }
      } else {
        setLabelSupport(true);
        let reason = `Original Carrier: ${originalCarrier} <br>Customer Country: ${customerCountryCode}<br> Return label could not be generated properly. Please handle manually`;
        const response = await sendMailToSupport(customerCountryCode, reason);
        if (response.status === 200) {
          setIsLoading(false);
        } else {
          setLabelSupport(false);
          setSupportError(true);
          setIsLoading(false);
        }
      }
    } else {
      setSupportError(true);
      setIsLoading(false);
    }
  };

  const backToArticleList = () => {
    const unchecked = articleList.map((article) => {
      return { ...article, checked: false, returnReason: null };
    });

    sessionStorage.removeItem("returnList");
    sessionStorage.setItem("articleList", JSON.stringify(unchecked));
    setArticleList(JSON.parse(sessionStorage.getItem("articleList")));
    setMissingReasonList([]);
    navigate("/");
  };

  const cancelReturnProcess = () => {
    localStorage.clear();
    sessionStorage.clear();
    setUserData(null);
    setOrderData(null);
    setArticleList(null);
    setReturnList(null);
    setUserComment("");
    setMissingReasonList(null);
    setUps(false);
    setDhl(false);
    setDhlToUpsReturn(false);
    setLabelSupport(false);
    setExceptionSwitzerland(false);
    setSupportError(false);
    setEmailSent(false);
    setCommentPosted(false);
    setIsLoading(false);
    setMissingReasonList([]);

    navigate("/");
  };

  const handleCancelClick = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  };

  const getDaysDifference = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
  
    if (isNaN(d1) || isNaN(d2)) return null; // Handle invalid dates
  
    // Convert both dates to midnight to ignore time differences
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
  
    const timeDiff = Math.abs(d2 - d1); // Get difference in milliseconds
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert to days
  };

  if (!userData) {
    return (
      <div className="main-container">
        <Routes>
          <Route
            path="/"
            element={
              <LoginForm
                onLoggedIn={onLoggedIn}
                returnAPIBaseUrl={returnAPIBaseUrl}
              />
            }
          />
        </Routes>
      </div>
    );
  } else {
    return (
      <div className="main-container">
        {userData && orderData && (
          <CustomerCard
            firstName={orderData.customer.firstName}
            lastName={orderData.customer.lastName}
            orderNumber={orderData.orderNumber}
            email={orderData.customer.email}
            supportLogin={userData.supportLogin}
          />
        )}
        {articleList && (
          <Routes>
            <Route
              path="/"
              element={
                <ArticleList
                  articleList={articleList}
                  handleCancelClick={handleCancelClick}
                  toggleCheck={toggleCheck}
                  handleReturnReasonSelect={handleReturnReasonSelect}
                  handleQuantitySelect={handleQuantitySelect}
                  handleListSubmit={handleListSubmit}
                  missingReasonList={missingReasonList}
                />
              }
            />
            <Route
              path="/returns"
              element={
                <ReturnList
                  returnList={returnList}
                  orderData={orderData}
                  backToArticleList={backToArticleList}
                  cancelReturnProcess={cancelReturnProcess}
                  handleUserCommentChange={handleUserCommentChange}
                  userComment={userComment}
                  handleLabelRequest={handleLabelRequest}
                />
              }
            />
            <Route
              path="/confirmation"
              element={
                <Confirmation
                  ups={ups}
                  dhl={dhl}
                  dhlToUps={dhlToUpsReturn}
                  email={orderData.customer.email}
                  labelSupport={labelSupport}
                  supportError={supportError}
                  isLoading={isLoading}
                  terminate={cancelReturnProcess}
                  emailSent={emailSent}
                  commentPosted={commentPosted}
                  exceptionSwitzerland={exceptionSwitzerland}
                />
              }
            />
          </Routes>
        )}
      </div>
    );
  }
}

export default function WrappedApp() {
  return (
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  );
}
